import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'

import productMedicareAdvantage from '../products-and-programs+/product-medicare-advantage.png'
import productMedicareSupplement from '../products-and-programs+/product-medicare-supplement.png'
import productPrescriptions from '../products-and-programs+/product-prescriptions.png'

const SnowDayAlert = ({
	alert,
}: {
	alert: {
		heading: string
		message: JSX.Element
	} | null
}) => {
	if (alert === null) {
		return <></>
	} else {
		return (
			<div className="m-10 rounded-md bg-yellow-50 p-6 text-2xl">
				<div className="flex">
					<div className="flex-shrink-0">
						<ExclamationCircleIcon
							className="h-10 w-10 text-yellow-400"
							aria-hidden="true"
						/>
					</div>
					<div className="ml-6">
						<h3 className="text-xl font-medium text-yellow-800">
							{alert.heading}
						</h3>
						<div className="mt-4 text-xl text-yellow-700">{alert.message}</div>
					</div>
				</div>
			</div>
		)
	}
}

const testimonials = [
	{
		id: 1,
		quote:
			'I have had my insurance with the Stan Steele agency for many years. In times of need, they have gone above and beyond what you would expect for an insurance agency. They always put their customers first.',
		attribution: 'Carol Irby',
	},
	{
		id: 2,
		quote:
			'Phenomenal staff who cares about their clients. Best choice my family and I ever made. They handle all of our needs, from our camper, to our house, to our golf cart. A++++ experience!!',
		attribution: 'Christopher DiMeno',
	},
	{
		id: 3,
		quote:
			'Our family has used the Steele Agency for many, many years.   We have always had a great experience! We trust their advice and appreciate their courtesy and professionalism!',
		attribution: 'Debbie Delforte',
	},
]

const snowDayAlert = null
// const snowDayAlert = {
// 	heading: 'Internet Service Disruption',
// 	message: (
// 		<p>
// 			Our office internet connection is experiencing issues as of 1:30p.m.
// 			Tuesday July 2. If you need assistance and are unable to reach us by
// 			phone, please send an email to{' '}
// 			<a href="mailto:support@steeleagency.com" className="underline">
// 				support@steeleagency.com
// 			</a>{' '}
// 			and we will access email messages using a backup connection.
// 		</p>
// 	),
// }
// const snowDayAlert = {
// 	heading: 'Memorial Day Holiday',
// 	message:
// 		'Our office is closed on Monday May 31st for the Memorial Day holiday. We will reopen at 8am on Tuesday June 1st.',
// }
export const meta: MetaFunction = () => [{ title: 'Stan Steele Insurance' }]

const featuredProducts = [
	{
		title: 'Medicare Advantage',
		path: 'products-and-programs/medicare-advantage',
		description:
			'Learn about plan options and enroll in a Medicare Advantage plan that fits your needs.',
		imageUrl: productMedicareAdvantage,
	},
	{
		title: 'Medicare Supplement',
		path: 'products-and-programs/medicare-supplement',
		description:
			'Find out how a Medicare Supplement plan can help cover your share of costs with Original Medicare.',
		imageUrl: productMedicareSupplement,
	},
	{
		title: 'Prescription Drug Plans',
		path: 'products-and-programs/medicare-part-d-prescription-drug-plans',
		description:
			'Get help with the cost of prescription drugs with a Medicare Part D Prescription Drug Plan.',
		imageUrl: productPrescriptions,
	},
]

export default function Index() {
	return (
		<main className="font-poppins grid h-full place-items-center">
			<div className="grid place-items-center px-4 py-16 xl:gap-24">
				<div className="flex flex-col items-center text-center xl:order-2 xl:items-start xl:text-left">
					<h1
						data-heading
						className="mt-8 animate-slide-top text-4xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:animate-slide-left xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						<a href="/">Stan Steele Insurance</a>
					</h1>
				</div>
			</div>
			<main className="mx-auto my-10 max-w-7xl">
				{snowDayAlert && <SnowDayAlert alert={snowDayAlert} />}

				<div className="mx-auto grid grid-cols-1 gap-8 md:grid-cols-3">
					<section>
						<p className="text-2xl font-medium text-gray-900">About Us</p>
						<ul className="mt-4 space-y-6 border-t border-gray-200 pt-6 sm:space-y-4">
							<li className="flex">
								<Link
									to="/about-us/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Our Story
								</Link>
							</li>

							<li className="flex">
								<Link
									to="../our-insurance-company-relationships/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Insurance Companies
								</Link>
							</li>
							<li className="flex">
								<Link
									to="success-stories"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Success Stories
								</Link>
							</li>
							<li className="flex">
								<Link
									to="/referrals/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Referral Program
								</Link>
							</li>
						</ul>
					</section>

					<section>
						<p className="text-2xl font-medium text-gray-900">Policy Service</p>
						<ul className="mt-4 space-y-6 border-t border-gray-200 pt-6 sm:space-y-4">
							<li className="flex">
								<Link
									to="/make-a-payment/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Make a Payment
								</Link>
							</li>
							<li className="flex">
								<Link
									to="/appointment/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Make an Appointment
								</Link>
							</li>

							<li className="flex">
								<a
									href="https://stan-steele-agency.typeform.com/to/xL8DnO"
									className="text-blue-600 underline"
									rel="noopener noreferrer"
									target="_blank"
								>
									Certificates of Insurance (opens in a new tab)
								</a>
							</li>
							<li className="flex">
								<Link
									to="/articles/accident-prevention-course/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Accident Prevention Course
								</Link>
							</li>
						</ul>
					</section>

					<section>
						<p className="text-2xl font-medium text-gray-900">Learn and Shop</p>
						<ul className="mt-4 space-y-6 border-t border-gray-200 pt-6 sm:space-y-4">
							<li className="flex">
								<Link
									to="/products-and-programs/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Products &amp; Programs
								</Link>
							</li>
							<li className="flex">
								<Link
									to="/articles/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Articles
								</Link>
							</li>
							<li className="flex">
								<Link
									to="/get-a-quote/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Get a Quote
								</Link>
							</li>
							<li className="flex">
								<Link
									to="/products-and-programs/medicare/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Medicare Insurance Plan Enrollment
								</Link>
							</li>
							<li className="flex">
								<Link
									to="/frequently-asked-questions/"
									className="text-blue-600 underline"
									prefetch="intent"
								>
									Frequently Asked Questions
								</Link>
							</li>
						</ul>
					</section>
				</div>

				<section className="bg-white py-16 sm:pt-16">
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<div className="mx-auto max-w-2xl text-center">
							<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
								Featured Products and Programs
							</h2>
							{/* <p className="mt-2 text-lg leading-8 text-gray-600">
								Learn how to grow your business with our expert advice.
							</p> */}
						</div>
						<div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
							{featuredProducts.map(product => (
								<article
									key={product.title}
									className="flex flex-col items-start justify-between"
								>
									<div className="relative w-full">
										<img
											src={product.imageUrl}
											alt={product.title}
											className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
										/>
										<div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
									</div>
									<div className="max-w-xl">
										<div className="group relative">
											<h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
												<Link to={product.path}>
													<span className="absolute inset-0" />
													{product.title}
												</Link>
											</h3>
											<p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
												{product.description}
											</p>
										</div>
									</div>
								</article>
							))}
						</div>
					</div>
				</section>
				{/* 
				<section className="bg-white py-24 sm:py-32">
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<div className="mx-auto max-w-2xl text-center">
							<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
								Community Spotlight
							</h2>
							<div>
								Our spotlight community organization of the month for July is{' '}
								<Link
									to="spotlight/wyatt-conklin-lawn-maintenance"
									className="text-blue-600 underline"
								>
									Wyatt Conklin Lawn Maintenance.
								</Link>
							</div>
						</div>
					</div>
				</section> */}

				<section
					aria-labelledby="testimonial-heading"
					className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-12"
				>
					<div className="mx-auto max-w-2xl lg:max-w-none">
						<div className="md:flex md:items-center md:justify-between">
							<h2
								id="testimonial-heading"
								className="text-2xl font-extrabold tracking-tight text-gray-900"
							>
								What are people saying?
							</h2>
							{/* <Link
								to="success-stories/"
								className="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block"
							>
								See more client reviews<span aria-hidden="true"> &rarr;</span>
							</Link> */}
						</div>
						<div className="mt-16 space-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
							{testimonials.map(testimonial => (
								<blockquote key={testimonial.id} className="sm:flex lg:block">
									<svg
										width={24}
										height={18}
										viewBox="0 0 24 18"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
										className="flex-shrink-0 text-gray-300"
									>
										<path
											d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
											fill="currentColor"
										/>
									</svg>
									<div className="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
										<p className="text-lg text-gray-600">{testimonial.quote}</p>
										<cite className="mt-4 block font-semibold not-italic text-gray-900">
											{testimonial.attribution}
										</cite>
									</div>
								</blockquote>
							))}
						</div>
					</div>

					<div className="mt-16 flex justify-center">
						<p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
							<span className="hidden md:inline">
								Over 3000 families and small businesses use our services to
								protect what matters most to them.
							</span>
							<Link
								to="success-stories"
								className="font-semibold text-indigo-600"
							>
								<span className="absolute inset-0" aria-hidden="true" /> Read
								our customer stories <span aria-hidden="true">&rarr;</span>
							</Link>
						</p>
					</div>
				</section>
			</main>
		</main>
	)
}
